import { useMemo, useCallback, useState, useRef } from "react"
import { Link } from "react-router-dom"
import { Box, Button, Chip, IconButton, Tooltip } from "@mui/material"
import MaterialReactTable, {
    MRT_ColumnDef,
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import {
    ColumnFiltersState,
    Getter,
    PaginationState,
    SortingState,
} from "@tanstack/react-table"
import { DateTime } from "luxon"
import { Cancel, Refresh, Visibility } from "@mui/icons-material"

import { FREQUENCIES } from "../../utils/config"
import UnsubscribeUser from "./unsubscribeUser"
import StatusButton from "../../component/statusButton"
import CustomDateFilter from "../../component/customDateFilter"
import CustomFilterPanel from "../../component/customFilterPanel"
import useFetchEntitiesPerPage from "../../hooks/useFetchEntitiesPerPage"
import useAccessControl from "../../hooks/useAccessControl"
import filterModel from "../../hooks/filterModel"

function UserSubscriptions() {
    const { cancelUserSubscription, userSubscriptionsList } = useAccessControl()
    const [columnFilters, setColumnFilters] =
        useState<ColumnFiltersState>() as any
    const [columnFilterFns, setColumnFilterFns] = useState({
        created_at: "is",
        customer_full_name: "contains",
        customer_phone_number: "contains",
        name: "contains",
        phone_number: "contains",
        amount: "contains",
        price_expiration: "is",
        frequency: "is",
        recurring_cycle: "=",
        status: "is",
        price_status: "is",
        cancelled_at: "is",
        updated_at: "is",
    }) as any
    const [globalFilter, setGlobalFilter] = useState<any>()
    const [sorting, setSorting] = useState<SortingState>() as any
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 15,
    })
    const [openUnsubscribeUser, setOpenUnsubscribeUser] =
        useState<boolean>(false)
    const userSubscriptionsData = useRef({}) as any

    const sort = useMemo(
        () =>
            sorting?.map(
                (item: {
                    id?: string
                    field?: string
                    sort?: string
                    desc?: string
                }) => ({
                    field: item?.id || item?.field,
                    sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
                })
            ),
        [sorting]
    )

    const {
        data: subscriptions,
        isLoading,
        refetch,
    } = useFetchEntitiesPerPage(
        {
            endPoint: "subscriptions",
            page: pagination.pageIndex,
            perPage: pagination.pageSize,
            filterModel: filterModel(columnFilterFns, columnFilters) as any,
            sortModel: sort,
            searchText: globalFilter,
        },
        {
            enabled: userSubscriptionsList,
        }
    ) as any

    // Event Handlers & Helpers
    const handleClickOpen = (id: string) => {
        setOpenUnsubscribeUser(true)
        userSubscriptionsData.current.id = id
    }
    const statusButton = useCallback(
        ({ row }: { row: { original: any } }) => (
            <StatusButton status={row.original.status} />
        ),
        []
    )
    const priceStatusButton = useCallback(
        ({ row }: { row: { original: any } }) => (
            <Box
                sx={{
                    mx: "2em",
                }}
            >
                {(row?.original?.price?.status !== "" ||
                    row?.original?.price?.status !== undefined) && (
                    <Button
                        key={row?.original?.id}
                        disableElevation
                        variant="contained"
                        fullWidth
                        sx={{
                            borderRadius: "10px",
                            fontWeight: 900,
                            py: "0.5em",
                            backgroundColor:
                                row?.original?.price?.status === "ACTIVE"
                                    ? "#E5FFEA"
                                    : row?.original?.price?.status === "PENDING"
                                    ? "#f5f5f5"
                                    : "#ffd6d6",
                            color:
                                row?.original?.price?.status === "ACTIVE"
                                    ? "#16C138"
                                    : row?.original?.price?.status === "PENDING"
                                    ? "#fbcf3b"
                                    : "red",
                            "&: hover": {
                                backgroundColor:
                                    row?.original?.price?.status === "ACTIVE"
                                        ? "#E5FFEA"
                                        : row?.original?.price?.status ===
                                          "PENDING"
                                        ? "#f5f5f5"
                                        : "#ffd6d6",
                                color:
                                    row?.original?.price?.status === "ACTIVE"
                                        ? "#16C138"
                                        : row?.original?.price?.status ===
                                          "PENDING"
                                        ? "#fbcf3b"
                                        : "red",
                            },
                        }}
                    >
                        {row?.original?.price?.status}
                    </Button>
                )}
            </Box>
        ),
        []
    )

    const actions = useCallback(
        ({ row }: { row: { original: any } }) => (
            <Box>
                <Tooltip title="View user subscription">
                    <Link to={`${row.original.id}/view`} state={row.original}>
                        <IconButton sx={{ color: "primary.main" }}>
                            <Visibility />
                        </IconButton>
                    </Link>
                </Tooltip>
                {cancelUserSubscription && (
                    <Tooltip title="Unsubscribe user">
                        <IconButton
                            color="error"
                            disabled={row.original.status !== "ACTIVE"}
                            onClick={() => handleClickOpen(row.original.id)}
                        >
                            <Cancel />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        ),
        []
    )
    const prices = useCallback(
        ({ row }: { row: { original: any } }) => (
            <Box
                sx={{
                    display: "inline-block",
                    justifyContent: "start",
                    alignItems: "flex-start",
                    fontWeight: "bold",
                }}
            >
                {row?.original?.prices?.length === 0
                    ? "-"
                    : row?.original?.prices?.map((price: any) =>
                          price.name ? (
                              <Chip
                                  key={price?.id}
                                  label={price?.name}
                                  sx={{
                                      my: 0.5,
                                      mr: 0.5,
                                      color: "primary.main",
                                  }}
                              />
                          ) : (
                              "-"
                          )
                      )}
            </Box>
        ),
        []
    )
    const dateFilter = useCallback(
        (dateProps: any) => <CustomDateFilter {...dateProps} />,
        []
    )

    // DATA STRUCTURE
    const columns = useMemo<MRT_ColumnDef<object>[]>(
        () => [
            {
                size: 300,
                accessorKey: "created_at",
                header: "Registered Time",
                filterVariant: "date" as any,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
                Cell: ({ cell }: { cell: { getValue: Getter<string> } }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: dateFilter as any,
            },
            {
                size: 240,
                accessorKey: "customer_full_name",
                header: "Customer Name",
                Cell: ({ row }: { row: { original: any } }) =>
                    row.original.customer.full_name,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            {
                size: 240,
                accessorKey: "customer_phone_number",
                header: "Customer Phone",
                Cell: ({ row }: { row: { original: any } }) =>
                    row.original.customer.phone_number,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            {
                size: 240,
                accessorKey: "name",
                header: "Plan Name",
                Cell: ({ row }: { row: { original: any } }) =>
                    row.original.price.name,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            {
                size: 240,
                accessorKey: "amount",
                header: "Amount",
                Cell: ({ row }: { row: { original: any } }) =>
                    `${row.original.price.amount} ${row.original.currency.code}`,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            {
                size: 240,
                accessorKey: "frequency",
                header: "Frequency",
                filterVariant: "select" as any,
                filterSelectOptions: FREQUENCIES,
                Cell: ({ row }: { row: { original: any } }) =>
                    row.original.price.frequency,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            {
                size: 240,
                accessorKey: "recurring_cycle",
                header: "Recurring Cycle",
                filterVariant: "number" as any,
                Cell: ({ row }: { row: { original: any } }) =>
                    row.original.price.recurring_cycle,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            {
                size: 240,
                accessorKey: "grace_period",
                header: "Grace Period (Hr)",
                enableSorting: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
                Cell: ({ row }: { row: { original: any } }) =>
                    row.original.price.grace_period,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            {
                size: 240,
                accessorKey: "price_expiration",
                header: "Expires At",
                filterVariant: "date" as any,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
                Cell: ({ row }: { row: { original: any } }) => {
                    const expired =
                        DateTime.now().startOf("day") >
                        DateTime.fromISO(row.original.price.expiration).startOf(
                            "day"
                        )
                    return expired ? (
                        <Button
                            size="small"
                            disableElevation
                            variant="contained"
                            sx={{
                                backgroundColor: "#ffd6d6",
                                color: "red",
                                "&: hover": {
                                    backgroundColor: "#ffd6d6",
                                    color: "red",
                                },
                            }}
                        >
                            Expired
                        </Button>
                    ) : (
                        DateTime.fromISO(
                            row.original.price.expiration
                        ).toFormat("ff")
                    )
                },
                Filter: dateFilter as any,
            },
            {
                size: 300,
                accessorKey: "cancelled_at",
                header: "Cancelled At",
                filterVariant: "date" as any,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
                Cell: ({ cell }: { cell: { getValue: Getter<string> } }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: dateFilter as any,
            },
            {
                size: 300,
                accessorKey: "cancellation_detail",
                header: "Cancellation Reason",
                enableSorting: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
                Cell: ({ row }: { row: { original: any } }) =>
                    row.original.cancellation_detail.reason,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            {
                size: 280,
                accessorKey: "price_status",
                header: "Subscription Plan Status",
                filterVariant: "select" as any,
                filterSelectOptions: ["ACTIVE", "INACTIVE", "PENDING"],
                Cell: priceStatusButton,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            {
                size: 240,
                accessorKey: "status",
                header: "Subscription Status",
                filterVariant: "select" as any,
                filterSelectOptions: [
                    "ACTIVE",
                    "INACTIVE",
                    "PENDING",
                    "CANCELED",
                    "EXPIRED",
                    "PROCESSING",
                ],
                Cell: statusButton,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            {
                size: 300,
                accessorKey: "updated_at",
                header: "Last Updated",
                filterVariant: "date" as any,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
                Cell: ({ cell }: { cell: { getValue: Getter<string> } }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: dateFilter as any,
            },
            {
                size: 240,
                accessorKey: "actions",
                header: "Actions",
                enableSorting: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
                Cell: actions,
            },
        ],
        [actions, dateFilter, prices, priceStatusButton, statusButton]
    )
    return (
        <Box sx={{ height: "100%" }}>
            {openUnsubscribeUser && (
                <UnsubscribeUser
                    id={userSubscriptionsData.current.id}
                    open={openUnsubscribeUser}
                    setOpen={setOpenUnsubscribeUser}
                    refetch={() => refetch()}
                />
            )}
            <Box sx={{ flex: 1, height: "100%" }}>
                <MaterialReactTable
                    data={subscriptions ? subscriptions.data : []}
                    columns={columns}
                    initialState={{
                        columnPinning: {
                            left: ["mrt-row-select"],
                            right: ["actions"],
                        },
                    }}
                    enableColumnResizing
                    enableColumnFilterModes
                    enableStickyHeader
                    enableColumnOrdering
                    enableRowSelection
                    enablePinning
                    manualFiltering
                    manualPagination
                    manualSorting
                    filterFns={{
                        after: (row: any, filterValue) =>
                            row.customField === filterValue,
                    }}
                    muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                        helperText: `Filter Mode: ${
                            columnFilterFns[column?.id]
                        }`,
                    })}
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setColumnFilterFns}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            <Tooltip arrow title="Refresh Data">
                                <IconButton onClick={() => refetch()}>
                                    <Refresh />
                                </IconButton>
                            </Tooltip>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <MRT_ToggleFiltersButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <MRT_FullScreenToggleButton table={table} />
                        </>
                    )}
                    muiTableContainerProps={{
                        sx: { maxHeight: `calc(100vh - 225px)` },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            "& .Mui-TableHeadCell-Content": {
                                justifyContent: "space-between",
                            },
                        },
                    }}
                    muiTableBodyCellProps={({ table, column }) => {
                        return {
                            sx: {
                                "&.MuiTableCell-root": {
                                    boxShadow:
                                        table.getState().columnPinning
                                            ?.right?.[0] === column?.id
                                            ? "-7px 0px 10px -1.7px lightgray"
                                            : table
                                                  .getState()
                                                  .columnPinning?.left?.some(
                                                      (el) => el === column.id
                                                  )
                                            ? "7px 0px 10px -1.7px lightgray"
                                            : "none",
                                },
                            },
                        }
                    }}
                    rowCount={subscriptions?.meta_data?.total ?? 0}
                    state={{
                        columnFilters: columnFilters || [],
                        columnFilterFns,
                        globalFilter: globalFilter || "",
                        isLoading,
                        pagination,
                        sorting: sorting || [],
                        showSkeletons: isLoading,
                    }}
                />
            </Box>
        </Box>
    )
}

export default UserSubscriptions
