/* eslint-disable react/jsx-pascal-case */
import { useMemo, useCallback, useState } from "react"
import {
    ColumnFiltersState,
    Getter,
    PaginationState,
    SortingState,
} from "@tanstack/react-table"
import {
    MaterialReactTable,
    MRT_ColumnDef,
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import { DateTime } from "luxon"
import { Refresh } from "@mui/icons-material"
import { Avatar, Box, Button, IconButton, Tooltip } from "@mui/material"

import filterModel from "../../hooks/filterModel"
import useAccessControl from "../../hooks/useAccessControl"
import CustomDateFilter from "../../component/customDateFilter"
import CustomFilterPanel from "../../component/customFilterPanel"
import { formatToTwoDecimalPlaces } from "../../utils/formatNumber"
import useFetchEntitiesPerPage from "../../hooks/useFetchEntitiesPerPage"

function Transactions() {
    const { transactionsList } = useAccessControl()
    const [columnFilters, setColumnFilters] =
        useState<ColumnFiltersState>() as any
    const [columnFilterFns, setColumnFilterFns] = useState({
        type: "is",
        amount: "=",
        status: "is",
        note: "contains",
        created_at: "is",
        payment_type: "is",
        customer_phone: "contains",
        payment_method_type: "contains",
        payment_reference_number: "contains",
    }) as any
    const [globalFilter, setGlobalFilter] = useState<any>()
    const [sorting, setSorting] = useState<SortingState>() as any
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 15,
    })

    const sort = useMemo(
        () =>
            sorting?.map(
                (item: {
                    id?: string
                    field?: string
                    sort?: string
                    desc?: string
                }) => ({
                    field: item?.id || item?.field,
                    sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
                })
            ),
        [sorting]
    )

    const {
        data: transactions,
        isLoading,
        refetch,
    } = useFetchEntitiesPerPage(
        {
            endPoint: `transactions`,
            page: pagination.pageIndex,
            perPage: pagination.pageSize,
            filterModel: filterModel(columnFilterFns, columnFilters) as any,
            sortModel: sort,
            searchText: globalFilter,
        },
        {
            enabled: transactionsList,
        }
    ) as any

    const dateFilter = useCallback(
        (dateProps: any) => <CustomDateFilter {...dateProps} />,
        []
    )
    const getType = useCallback(
        ({ cell }: { cell: { getValue: Getter<string> } }) => (
            <Box>
                {cell?.getValue() !== "" && (
                    <Button
                        key={cell?.getValue()}
                        size="small"
                        disableElevation
                        variant="contained"
                        sx={{
                            backgroundColor: "#f5f5f5",
                            color: "#fbcf3b",
                            "&: hover": {
                                backgroundColor: "#f5f5f5",
                                color: "#fbcf3b",
                            },
                            fontWeight: "bold",
                        }}
                    >
                        {cell?.getValue()}
                    </Button>
                )}
            </Box>
        ),
        []
    )
    const getPaymentType = useCallback(
        ({ row }: any) => (
            <Box>
                {row?.original?.detail?.type !== "" && (
                    <Button
                        key={row?.original?.id}
                        size="small"
                        disableElevation
                        variant="contained"
                        sx={{
                            backgroundColor: "#f5f5f5",
                            color: "black",
                            "&: hover": {
                                backgroundColor: "#f5f5f5",
                                color: "black",
                            },
                            fontWeight: "bold",
                            textTransform: "none",
                        }}
                    >
                        {row?.original?.detail?.type}
                    </Button>
                )}
            </Box>
        ),
        []
    )
    const getStatus = useCallback(
        ({ row }: any) => (
            <Box>
                {row?.original?.status !== "" && (
                    <Button
                        key={row?.original?.id}
                        size="small"
                        disableElevation
                        variant="contained"
                        sx={{
                            backgroundColor:
                                row?.original?.status === "APPROVED"
                                    ? "#e5faea"
                                    : "#ffd6d6",
                            color:
                                row?.original?.status === "APPROVED"
                                    ? "#33ca51"
                                    : "red",
                            "&: hover": {
                                backgroundColor:
                                    row?.original?.status === "APPROVED"
                                        ? "#e5faea"
                                        : "#ffd6d6",
                                color:
                                    row?.original?.status === "APPROVED"
                                        ? "#33ca51"
                                        : "red",
                            },
                        }}
                    >
                        {row?.original?.status}
                    </Button>
                )}
            </Box>
        ),
        []
    )

    // DATA STRUCTURE
    const columns = useMemo<MRT_ColumnDef<object>[]>(
        () => [
            {
                size: 220,
                accessorKey: "created_at",
                header: "Date",
                filterVariant: "date" as any,
                Cell: ({ cell }: { cell: { getValue: Getter<string> } }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                renderColumnFilterModeMenuItems: CustomFilterPanel,
                Filter: dateFilter,
            },
            {
                size: 220,
                accessorKey: "payment_reference_number",
                header: "Ref. No.",
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            {
                size: 230,
                accessorKey: "customer_phone",
                header: "Phone",
                Cell: ({ row }: { row: any }) =>
                    row?.original?.detail?.customer_phone ?? "",
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            {
                size: 180,
                accessorKey: "amount",
                header: "Amount",
                align: "left",
                headerAlign: "left",
                filterVariant: "number" as any,
                Cell: ({ row }: { row: any }) =>
                    `${formatToTwoDecimalPlaces(
                        Number(row?.original?.detail?.amount ?? 0)
                    )} ETB`,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            {
                size: 220,
                accessorKey: "type",
                header: "Transaction Type",
                filterVariant: "select",
                filterSelectOptions: ["TRANSFER", "DEPOSIT", "WITHDRAW"],
                Cell: getType,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            {
                size: 220,
                accessorKey: "payment_type",
                header: "Payment Type",
                filterVariant: "select",
                filterSelectOptions: ["ONETIME", "RECURRING"],
                Cell: getPaymentType,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            {
                size: 220,
                accessorKey: "payment_method_type",
                header: "Method",
                Cell: ({ row }: any) => (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            bgcolor: "#fff",
                            borderRadius: 6,
                            py: 1,
                            px: 2,
                            mb: 0.5,
                            mr: 0.5,
                        }}
                    >
                        <Avatar
                            sx={{
                                width: 30,
                                height: 30,
                                mr: 1,
                            }}
                            src={row?.original?.detail?.payment_method_logo}
                        />
                        {row?.original?.detail?.payment_method_type?.replace(
                            "_",
                            " "
                        )}
                    </Box>
                ),
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            {
                size: 220,
                accessorKey: "status",
                header: "Status",
                filterVariant: "select",
                filterSelectOptions: ["APPROVED", "PENDING", "REJECTED"],
                Cell: getStatus,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            {
                size: 300,
                accessorKey: "note",
                header: "Note",
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
        ],
        [dateFilter, getPaymentType, getStatus, getType]
    )
    return (
        <Box sx={{ height: "100%" }}>
            <Box sx={{ flex: 1, height: "100%" }}>
                <MaterialReactTable
                    data={transactions ? transactions?.data : []}
                    columns={columns}
                    initialState={{
                        columnPinning: {
                            left: ["mrt-row-select"],
                            right: ["actions"],
                        },
                    }}
                    enableColumnResizing
                    enableColumnFilterModes
                    enableStickyHeader
                    enableColumnOrdering
                    enableRowSelection
                    enablePinning
                    manualFiltering
                    manualPagination
                    manualSorting
                    filterFns={{
                        after: (row: any, filterValue) =>
                            row.customField === filterValue,
                    }}
                    muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                        helperText: `Filter Mode: ${
                            columnFilterFns[column?.id]
                        }`,
                    })}
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setColumnFilterFns}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            <Tooltip arrow title="Refresh Data">
                                <IconButton onClick={() => refetch()}>
                                    <Refresh />
                                </IconButton>
                            </Tooltip>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <MRT_ToggleFiltersButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <MRT_FullScreenToggleButton table={table} />
                        </>
                    )}
                    muiTableContainerProps={{
                        sx: { maxHeight: `calc(100vh - 225px)` },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            "& .Mui-TableHeadCell-Content": {
                                justifyContent: "space-between",
                            },
                        },
                    }}
                    muiTableBodyCellProps={({ table, column }) => {
                        return {
                            sx: {
                                "&.MuiTableCell-root": {
                                    boxShadow:
                                        table.getState().columnPinning
                                            ?.right?.[0] === column?.id
                                            ? "-7px 0px 10px -1.7px lightgray"
                                            : table
                                                  .getState()
                                                  .columnPinning?.left?.some(
                                                      (el) => el === column.id
                                                  )
                                            ? "7px 0px 10px -1.7px lightgray"
                                            : "none",
                                },
                            },
                        }
                    }}
                    rowCount={transactions?.meta_data?.total ?? 0}
                    state={{
                        columnFilters: columnFilters || [],
                        columnFilterFns,
                        globalFilter: globalFilter || "",
                        isLoading,
                        pagination,
                        sorting: sorting || [],
                        showSkeletons: isLoading,
                    }}
                />
            </Box>
        </Box>
    )
}

export default Transactions
