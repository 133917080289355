import { SnackbarProvider } from "notistack"
import { Box, Hidden, Slide } from "@mui/material"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

import { AuthProvider } from "./context/authProvider"
import { MERCHANT_ADMIN_BASE_URL, SSO_BASE_URL } from "./utils/config"

import Main from "./container/main"
import Listener from "./component/listener"
import IPIframe from "./component/ipIframe"
import Authorize from "./component/authorize"
import PrivateRoute from "./component/privateRoute"
import PageNotFound from "./component/pageNotFound"
import PersistLogin from "./component/persistLogin"

import SignIn from "./pages/signIn"
import Roles from "./pages/roles/roles"
import Users from "./pages/users/users"
import Registration from "./pages/registration"
import Products from "./pages/products/products"
import UserMerchants from "./pages/userMerchants"
// import Dashboard from "./pages/dashboard/dashboard"
import UserDetails from "./pages/users/userDetails"
import Customers from "./pages/customers/customers"
import Discounts from "./pages/discounts/discounts"
import AccountDetails from "./pages/account/account"
import Credentials from "./pages/credentials/credentials"
import UpdateProduct from "./pages/products/updateProduct"
import ComplianceData from "./pages/merchantComplianceData"
import ProductDetails from "./pages/products/productDetails"
import Transactions from "./pages/transactions/transactions"
import OneTimePayments from "./pages/payments/oneTimePayments"
import DiscountsByCode from "./pages/discounts/discountsByCode"
import Subscriptions from "./pages/userSubscriptions/userSubscriptions"
import UserSubscriptionDetails from "./pages/userSubscriptions/userSubscriptionDetails"

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            retry: false,
        },
    },
})

function App() {
    return (
        <Box>
            {/* Single Sign out Iframe */}
            {window.parent === window && (
                <iframe
                    id="ipIframe"
                    src={`${MERCHANT_ADMIN_BASE_URL}/IPIFrame`}
                    title="ipIframe"
                    style={{ display: "none" }}
                />
            )}
            {window.parent === window && (
                <iframe
                    id="opIframe"
                    src={`${SSO_BASE_URL}/opIframe`}
                    title="opIframe"
                    style={{ display: "none" }}
                />
            )}
            <QueryClientProvider client={queryClient}>
                <SnackbarProvider
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    TransitionComponent={Slide}
                    autoHideDuration={3000}
                    preventDuplicate
                >
                    <BrowserRouter>
                        <AuthProvider>
                            {window.parent === window && (
                                <Hidden>
                                    <Listener />
                                </Hidden>
                            )}
                            <Routes>
                                <Route path="login" element={<SignIn />} />
                                <Route
                                    path="authorize"
                                    element={<Authorize />}
                                />
                                <Route path="IPIFrame" element={<IPIframe />} />
                                <Route element={<PersistLogin />}>
                                    <Route
                                        path="register"
                                        element={
                                            <PrivateRoute>
                                                <Registration />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/"
                                        element={
                                            <PrivateRoute>
                                                <UserMerchants>
                                                    <Main />
                                                </UserMerchants>
                                            </PrivateRoute>
                                        }
                                    >
                                        <Route
                                            path="/compliance-data"
                                            element={<ComplianceData />}
                                        />
                                        {/* <Route
                                            path=""
                                            element={<Dashboard />}
                                        /> */}
                                        <Route
                                            path="account"
                                            element={<AccountDetails />}
                                        />
                                        <Route
                                            path="products"
                                            element={<Products />}
                                        />
                                        <Route
                                            path="products/:productId/view"
                                            element={<ProductDetails />}
                                        />
                                        <Route
                                            path="products/:productId/edit"
                                            element={<UpdateProduct />}
                                        />
                                        <Route
                                            path="subscriptions"
                                            element={<Subscriptions />}
                                        />
                                        <Route
                                            path="subscriptions/:userSubId/view"
                                            element={
                                                <UserSubscriptionDetails />
                                            }
                                        />
                                        <Route
                                            path="customers"
                                            element={<Customers />}
                                        />
                                        <Route
                                            path="roles"
                                            element={<Roles />}
                                        />
                                        <Route
                                            path="users"
                                            element={<Users />}
                                        />
                                        <Route
                                            path="/users/:userPhone/details"
                                            element={<UserDetails />}
                                        />
                                        <Route
                                            path="/credentials"
                                            element={<Credentials/>}
                                        />
                                        <Route
                                            path="discountCodes"
                                            element={<Discounts />}
                                        />
                                        <Route
                                            path="discountCodes/:discountCode/discounts"
                                            element={<DiscountsByCode />}
                                        />
                                        <Route
                                            path="one-time-payments"
                                            element={<OneTimePayments />}
                                        />
                                        <Route
                                          path="transactions"
                                          element={<Transactions />}
                                        />
                                        <Route
                                            path="*"
                                            element={<PageNotFound />}
                                        />
                                    </Route>
                                </Route>
                            </Routes>
                        </AuthProvider>
                    </BrowserRouter>
                </SnackbarProvider>
            </QueryClientProvider>
        </Box>
    )
}

export default App
