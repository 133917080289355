import React, { useContext, useState } from "react"
import {
    Box,
    Typography,
    Card,
    CardContent,
    Divider,
    Button,
    Grid,
    Stack,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Autocomplete,
    CircularProgress,
} from "@mui/material"
import { AccountBalanceWallet, Send, Wallet } from "@mui/icons-material"
import useFetchEntities from "../../hooks/useFetchEntities"
import AuthContext from "../../context/authProvider"
import useAccessControl from "../../hooks/useAccessControl"

interface Account {
    id: string
    balance: number
    opening_balance: number
}

const paymentMethods = ["Telebirr", "CBE", "ENAT Bank"]

const WalletDashboard: React.FC = () => {
    const { merchantId } = useContext(AuthContext) as any
    const { accountDetails } = useAccessControl()
    const [account, setAccount] = useState<Account>({
        id: "",
        balance: 100081965.0,
        opening_balance: 500.0,
    })

    const [isTransferDialogOpen, setIsTransferDialogOpen] = useState(false)
    const [transferAmount, setTransferAmount] = useState<number>(0)
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
        string | null
    >(null)

    const handleOpenTransferDialog = () => setIsTransferDialogOpen(true)
    const handleCloseTransferDialog = () => setIsTransferDialogOpen(false)

    const handleTransfer = () => {
        if (
            transferAmount > 0 &&
            transferAmount <= account.balance &&
            selectedPaymentMethod
        ) {
            setAccount((prev) => ({
                ...prev,
                balance: prev.balance - transferAmount,
            }))
            setTransferAmount(0)
            setSelectedPaymentMethod(null)
            handleCloseTransferDialog()
        }
    }
    const { data, isLoading } = useFetchEntities(
        {
            endPoint: `/merchants/${merchantId}/accounts`,
        },
        { enabled: accountDetails }
    ) as any
    console.log("data", data?.data, accountDetails)
    if (isLoading)
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
                minHeight: "40vh",
            }}
        >
            <CircularProgress
                size={50}
                sx={{
                    color: "secondary.main",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                }}
            />
        </Box>
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
                p: 3,
                bgcolor: "#f4f6f8",
            }}
        >
            {/* Wallet Overview */}
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Card sx={{ borderRadius: 3, boxShadow: 3 }}>
                        <CardContent>
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                            >
                                <Wallet color="primary" fontSize="large" />
                                <Box>
                                    <Typography variant="h6" fontWeight="600">
                                        Opening Balance
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        fontWeight="bold"
                                        color="#FFAA00"
                                    >
                                        ETB {data?.data?.opening_balance}
                                    </Typography>
                                </Box>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Transfer Section */}
                <Grid item xs={12} md={6}>
                    <Card sx={{ borderRadius: 3, boxShadow: 3 }}>
                        <CardContent>
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    alignItems="center"
                                >
                                    <AccountBalanceWallet
                                        color="primary"
                                        fontSize="large"
                                    />
                                    <Box>
                                        <Typography
                                            variant="h6"
                                            fontWeight="600"
                                        >
                                            Current Balance
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            fontWeight="bold"
                                            color="success.main"
                                        >
                                            ETB {data?.data?.balance}
                                        </Typography>
                                    </Box>
                                </Stack>
                                <Button
                                    variant="contained"
                                    onClick={handleOpenTransferDialog}
                                    sx={{
                                        borderRadius: 2,
                                        textTransform: "none",
                                        fontWeight: 600,
                                        color: "secondary.main",
                                    }}
                                    startIcon={<Send />}
                                >
                                    Withdraw
                                </Button>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/* Recent Transactions */}
            <Paper
                sx={{
                    p: 3,
                    borderRadius: 3,
                    boxShadow: 3,
                    overflowY: "auto",
                    minHeight: `calc(100vh-300px)`,
                }}
            >
                <Typography variant="h6" fontWeight="600" gutterBottom>
                    Recent Transactions
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Typography color="text.secondary"/>
            </Paper>

            {/* Transfer Dialog */}
            <Dialog
                open={isTransferDialogOpen}
                onClose={handleCloseTransferDialog}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>Withdraw Funds</DialogTitle>
                <DialogContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        mt: 1,
                    }}
                >
                    <Typography variant="body1">
                        Enter the amount to withdraw
                    </Typography>
                    <TextField
                        type="number"
                        value={transferAmount}
                        onChange={(e) =>
                            setTransferAmount(Number(e.target.value))
                        }
                        fullWidth
                        placeholder="Amount"
                    />
                    <Autocomplete
                        options={paymentMethods}
                        value={selectedPaymentMethod}
                        onChange={(_, newValue) =>
                            setSelectedPaymentMethod(newValue)
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select Payment Method"
                                variant="outlined"
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseTransferDialog}
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleTransfer}
                        color="primary"
                        disabled={
                            transferAmount <= 0 ||
                            transferAmount > account.balance ||
                            !selectedPaymentMethod
                        }
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default WalletDashboard
