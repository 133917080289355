import { TextField, TextFieldProps } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon"
import { DateTime } from "luxon"
import { merge } from "lodash"
import { DesktopDateTimePicker } from "@mui/x-date-pickers"

interface CustomDateTimePickerProps {
    InputAdornmentProps?: object
    InputProps?: TextFieldProps["InputProps"]
    value: string
    label?: string
    date1?: string | null
    date2?: string | null
    onChange: (newValue: string) => void
    [key: string]: any // For other user-defined props
}

function CustomDateTimePicker(props: CustomDateTimePickerProps) {
    const {
        InputAdornmentProps,
        InputProps: userInputProps,
        value,
        label,
        date1,
        date2,
        onChange,
        ...otherUserProps
    } = props

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DesktopDateTimePicker
                label={label}
                value={value ? DateTime.fromISO(value) : null}
                // disableFuture
                minDate={
                    date2 ? DateTime.fromISO(date2) || new Date() : undefined
                }
                maxDate={
                    date1 ? DateTime.fromISO(date1) || new Date() : undefined
                }
                onChange={() => true}
                {...InputAdornmentProps}
                onAccept={(newValue) => {
                    onChange(newValue ? newValue?.toString() : "")
                }}
                slots={{
                    textField: (params) => (
                        <TextField
                            {...params}
                            {...otherUserProps}
                            fullWidth
                            InputProps={merge(
                                params.InputProps,
                                userInputProps
                            )}
                        />
                    ),
                }}
            />
        </LocalizationProvider>
    )
}
export default CustomDateTimePicker
