import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import { Link as RouterLink } from "react-router-dom"
import { NavigateNext } from "@mui/icons-material"
import useBreakPoints from "../hooks/useBreakPoints"
import { capitalizeFirstLetter } from "../utils/capitalizeWord"

const breadcrumbNameMap = {
    "/": "Dashboard",
    "/products": "Products",
    "/customers": "Customers",
    "/subscriptions": "Subscriptions",
    "/roles": "Roles",
    "/users": "Users",
    "/transactions": "Transactions",
    "/discountCodes": "Discount Codes",
    "/one-time-payments": "One Time Payments",
}

function LinkRouter(props: any) {
    return <Link {...props} component={RouterLink} />
}

function BreadCrumbPaths({
    to,
    pathnames,
}: {
    to: string
    pathnames: string[]
}) {
    const first = `/${pathnames[0]}`
    const { xs, sm } = useBreakPoints()
    const mobile = xs && !sm

    return first !== to ? (
        <div>{pathnames[1]}</div>
    ) : (
        <LinkRouter
            style={{ fontSize: mobile ? 15 : 20 }}
            underline="hover"
            color="inherit"
            to={to}
            key={to}
        >
            {breadcrumbNameMap[to as keyof typeof breadcrumbNameMap]}
        </LinkRouter>
    )
}

function LastItem({ to, pathnames }: { to: string; pathnames: string[] }) {
    const lastWord = pathnames[pathnames.length - 1]

    return pathnames.length > 1 ? (
        <Typography
            color="black"
            variant="subtitle2"
            sx={{ fontSize: { xs: 15, sm: 20 } }}
            key={to}
        >
            {capitalizeFirstLetter(lastWord)}
        </Typography>
    ) : (
        <Typography
            color="black"
            variant="subtitle2"
            sx={{ fontSize: { xs: 15, md: 20 } }}
            key={to}
        >
            {breadcrumbNameMap[to as keyof typeof breadcrumbNameMap]}
        </Typography>
    )
}

function Page({ pathname }: { pathname: string }) {
    const pathnames: string[] =
        pathname === "/" ? [""] : pathname.split("/").filter((x: string) => x)
    return (
        <Breadcrumbs
            aria-label="breadcrumb"
            maxItems={2}
            separator={<NavigateNext fontSize="small" />}
        >
            {pathnames.map((value: any, index: number) => {
                const last = index === pathnames.length - 1
                const to = `/${pathnames.slice(0, index + 1).join("/")}`

                return last ? (
                    <LastItem to={to} pathnames={pathnames} />
                ) : (
                    <BreadCrumbPaths to={to} pathnames={pathnames} />
                )
            })}
        </Breadcrumbs>
    )
}

export default Page
