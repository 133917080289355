import { useContext, useEffect } from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import { useMutation } from "@tanstack/react-query"
import { CircularProgress, Grid, TextField, Typography } from "@mui/material"
import { useSnackbar } from "notistack"

import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import AuthContext from "../../context/authProvider"
import { Controller, useForm } from "react-hook-form"

interface UnsubscribeUserDialog {
    id: string
    open: boolean
    setOpen: (item: boolean) => void
    refetch: () => void
}

interface UnsubscribeUserForm {
    cancellation_reason: string
    feed_back: string
    id_type: string
    remark: string
}
export default function UnsubscribeUser({
    id,
    open,
    setOpen,
    refetch,
}: UnsubscribeUserDialog) {
    const { merchantId } = useContext(AuthContext) as any

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"))

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<UnsubscribeUserForm>({ mode: "onSubmit" })

    const { enqueueSnackbar } = useSnackbar()

    const axiosPrivate = useAxiosPrivate()

    const { isLoading, mutate, data } = useMutation(
        (formData: any) =>
            axiosPrivate.patch(
                `/merchants/${merchantId}/subscriptions/${id}/cancel`,
                formData
            ),
        {
            onError: (error: any) => {
                enqueueSnackbar(
                    error?.response?.data?.error?.field_error?.[0]
                        ?.description ||
                        error?.response?.data?.error?.message ||
                        "Request Failed",
                    {
                        variant: "error",
                    }
                )
                refetch()
            },
        }
    )

    const handleUnsubscribe = (form: any) => {
        mutate({ ...form, id_type: "subscription_id" })
    }

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        if (data) {
            enqueueSnackbar("Unsubscribed successfully", {
                variant: "success",
            })
            setOpen(false)
            refetch()
        }
    }, [data])

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="unsubscribe-user-dialog-title"
            >
                <DialogTitle
                    id="unsubscribe-user-dialog-title"
                    sx={{ bgcolor: "#fafafa" }}
                >
                    <Typography
                        variant="h5"
                        sx={{ textAlign: "center", fontWeight: "bold" }}
                    >
                        Unsubscribe User
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ m: 2 }}>
                    <Grid container columnSpacing={3}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Controller
                                name="remark"
                                control={control}
                                rules={{
                                    required: "Remark is required",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        label="Remark"
                                        fullWidth
                                        multiline
                                        margin="normal"
                                        autoComplete="remark"
                                        error={!!errors?.remark}
                                        helperText={
                                            errors?.remark
                                                ? errors.remark.message
                                                : ""
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 1.5,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                name="cancellation_reason"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        label="Cancellation Reason"
                                        fullWidth
                                        multiline
                                        margin="normal"
                                        autoComplete="cancellation_reason"
                                        error={!!errors?.cancellation_reason}
                                        helperText={
                                            errors?.cancellation_reason
                                                ? errors.cancellation_reason
                                                      .message
                                                : ""
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 1.5,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                name="feed_back"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        label="Feedback"
                                        fullWidth
                                        multiline
                                        margin="normal"
                                        autoComplete="feed_back"
                                        error={!!errors?.feed_back}
                                        helperText={
                                            errors?.feed_back
                                                ? errors.feed_back.message
                                                : ""
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 1.5,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ bgcolor: "#fafafa" }}>
                    <Button variant="contained" autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSubmit(handleUnsubscribe)}
                        autoFocus
                        color="error"
                    >
                        Unsubscribe
                        {isLoading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: "black",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                }}
                            />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
