import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material"
import axios, { AxiosError } from "axios"

import AuthContext from "../context/authProvider"
import Loading from "../component/loading"
import Unauthorized from "../component/unauthorized"
import ErrorPage, { ErrorResponse } from "../component/errorPage"
import useFetchEntities from "../hooks/useFetchEntities"
import { MERCHANT_ADMIN_BASE_URL, SSO_BASE_URL } from "../utils/config"
import { Business } from "@mui/icons-material"

export function Image(props: any) {
    const { src, alt, fit, text } = props

    return (
        <Box
            sx={{ position: "relative", display: "flex", alignItems: "center" }}
        >
            <img
                width="100%"
                height="100%"
                src={src}
                // onError={(e) => {
                //     e.currentTarget.src = "fallback"
                // }}
                alt={alt}
                style={{ objectFit: fit }}
            />
            {text && (
                <Typography
                    variant="h4"
                    sx={{
                        position: "absolute",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: "bold",
                        bottom: "1em",
                        color: "white",
                        letterSpacing: ".08em",
                    }}
                >
                    {text}
                </Typography>
            )}
        </Box>
    )
}

function Merchant({ merchant, onSelect, selected }: any) {
    const { logo, name, id } = merchant

    return (
        <Paper
            sx={{
                p: ".8em",
                "&:hover": {
                    backgroundColor: "lightgray",
                },
                border: selected ? "2px solid black" : "none",
                cursor: "pointer",
                "&.MuiPaper-elevation": {
                    boxShadow: "2px 3px 3px #00000021",
                },
            }}
        >
            <Grid
                container
                spacing={1.2}
                alignItems="center"
                onClick={() => onSelect(id)}
            >
                <Grid
                    item
                    xs={2}
                    sm={1}
                    md={2}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        sx={{
                            width: "40px",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {logo ? (
                            <Image src={logo} alt="logo" fit="fill" />
                        ) : (
                            <Business />
                        )}
                    </Box>
                </Grid>
                <Grid item xs={7} md={9}>
                    <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                        {name}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}

export const MerchantToggle = createContext({})
export const MerchantInfo = createContext({})

const ErrorMessage = (error: any) => {
    const err = error as AxiosError

    if (axios.isAxiosError(err)) {
        const newErr = err?.response?.data as ErrorResponse
        return <ErrorPage {...newErr} />
    }
}

function UserMerchants({ children }: any) {
    const { merchantId, setMerchantId } = useContext(AuthContext) as any
    const [selected, setSelected] = useState<string | boolean>(merchantId)
    const [selectedMerchant, setSelectedMerchant] = useState<any>()

    const { data, isSuccess, isError, error, isLoading, refetch } =
        useFetchEntities({
            endPoint: "users/permissions",
        }) as any
    const merchants = useMemo(
        () =>
            data?.data?.map(
                (element: { merchant: object }) => element.merchant
            ),
        [data?.data]
    )
    const value = useMemo(
        () => ({
            toggle: () => setSelected(false),
            merchants,
            data: data?.data,
        }),
        [merchants, data]
    )

    const merchantDetails = useMemo(
        () => ({
            refetch: refetch,
            selectedMerchant: selectedMerchant,
            userPermissions: data?.data?.find(
                (element: { merchant: { id: string } }) =>
                    element.merchant.id === selected
            )?.permissions,
            setSelectedMerchant: setSelectedMerchant,
        }),
        [refetch, selectedMerchant, selected, data]
    )

    const handleSignOut = () => {
        window.location.href = `${SSO_BASE_URL}/rpLogout?post_logout_redirect_uri=${MERCHANT_ADMIN_BASE_URL}/login`
        window.localStorage.removeItem("refresh token")
        window.localStorage.setItem("registered", "true")
    }
    if (merchants?.length === 1 && !selected) {
        setMerchantId(merchants[0].id)
        setSelected(merchants[0].id)
    }
    useEffect(() => {
        if (merchants?.length !== 0 && selected) {
            setSelectedMerchant(
                merchants?.find(
                    (merchant: { id: string }) => merchant.id === selected
                )
            )
        }
    }, [merchants, selected])

    if (!selected && merchants?.length > 1) {
        return (
            <Grid
                container
                spacing={1}
                sx={{
                    width: { lg: "80%", md: "90%", xs: "95%" },
                    display: "flex",
                    margin: "auto",
                    marginTop: { md: "2em" },
                    marginLeft: { lg: "6em" },
                    justifyContent: "space-between",
                }}
            >
                <Grid
                    item
                    md={6}
                    xs={12}
                    sx={{ p: ".5em", my: { xs: "-2.5em", sm: "-2em" } }}
                >
                    <Box
                        sx={{
                            mb: "-3em",
                            width: { md: "100%", sm: "auto" },
                            height: { md: "100%", sm: "auto" },
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Image
                            src={`${process.env.PUBLIC_URL}/assets/logo.png`}
                            alt="merchant-list"
                            fit="cover"
                        />
                    </Box>
                </Grid>
                <Grid item md={5} xs={12}>
                    <Box
                        sx={{
                            p: "2em",
                            display: "flex",
                            flexDirection: "column",
                            gap: "1em",
                        }}
                    >
                        <Typography sx={{ fontWeight: "500" }}>
                            Choose Merchant
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                overflow: "auto",
                                width: "100%",
                                maxHeight: {
                                    sm: `calc(100vh - 350px)`,
                                    xs: `calc(100vh - 350px)`,
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.5em",
                                    minWidth: "100%",
                                }}
                            >
                                {merchants?.map((merchant: any) => (
                                    <Merchant
                                        key={merchant.id}
                                        merchant={merchant}
                                        selected={merchantId === merchant.id}
                                        onSelect={(id: string) =>
                                            setMerchantId(id)
                                        }
                                    />
                                ))}
                            </Box>
                        </Box>
                        <Button
                            disabled={!merchantId}
                            variant="contained"
                            sx={{
                                height: "3em",
                                bgcolor: "primary.main",
                                color: "secondary.main",
                                ":hover": {
                                    bgcolor: "primary.main",
                                    color: "secondary.main",
                                },
                                textTransform: "none",
                            }}
                            onClick={() =>
                                setSelected(() => {
                                    if (merchantId) {
                                        window.localStorage.setItem(
                                            "merchantId",
                                            merchantId
                                        )
                                        return merchantId
                                    }
                                    return false
                                })
                            }
                        >
                            Sign In
                        </Button>
                        <Divider>Or</Divider>
                        <Button
                            variant="contained"
                            sx={{
                                height: "3em",
                                bgcolor: "secondary.main",
                                ":hover": {
                                    bgcolor: "secondary.main",
                                },
                                textTransform: "none",
                            }}
                            onClick={() => handleSignOut()}
                        >
                            Sign Out from SSO
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        )
    }

    if (selected && !merchantId)
        return (
            <Box sx={{ pt: "5em", color: "primary.main" }}>
                <Loading message="Please, wait a moment ..." />
            </Box>
        )

    return (
        <MerchantToggle.Provider value={value}>
            {merchants?.length > 0 && selected && !isLoading && (
                <MerchantInfo.Provider value={merchantDetails}>
                    {children}
                </MerchantInfo.Provider>
            )}
            {merchants?.length === 0 && isSuccess && <Unauthorized />}
            {isError && !selected && ErrorMessage(error)}
            {isLoading && (
                <Box sx={{ pt: "5em", color: "primary.main" }}>
                    <Loading message="Please, wait a moment ..." />
                </Box>
            )}
        </MerchantToggle.Provider>
    )
}

export default UserMerchants
