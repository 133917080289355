import { useMemo, useCallback, useState, useContext, useRef } from "react"
import { Link, useParams } from "react-router-dom"
import { Box, IconButton, Tooltip, Typography } from "@mui/material"
import { Delete, FiberManualRecord, Visibility } from "@mui/icons-material"
import MaterialReactTable, {
    MRT_ColumnDef,
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import {
    ColumnFiltersState,
    Getter,
    PaginationState,
    SortingState,
} from "@tanstack/react-table"
import { DateTime } from "luxon"

import useFetchEntitiesPerPage from "../../hooks/useFetchEntitiesPerPage"
import filterModel from "../../hooks/filterModel"

import StatusButton from "../../component/statusButton"
import PaymentMethods from "../../component/paymentMethod"
import CustomTimeLine from "../../component/customTimeLine"
import CustomDateFilter from "../../component/customDateFilter"
import CustomFilterPanel from "../../component/customFilterPanel"
import useFetchEntities from "../../hooks/useFetchEntities"
import { capitalizeFirstLetter } from "../../utils/capitalizeWord"
import { useMutation } from "@tanstack/react-query"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import { useSnackbar } from "notistack"
import AuthContext from "../../context/authProvider"
import useAccessControl from "../../hooks/useAccessControl"

function DiscountsByCode() {
    const { updateDiscount, discountDetails } = useAccessControl()
    const { merchantId } = useContext(AuthContext) as any
    const { discountCode } = useParams()
    const [columnFilters, setColumnFilters] =
        useState<ColumnFiltersState>() as any
    const [columnFilterFns, setColumnFilterFns] = useState({
        created_at: "is",
        name: "contains",
        duration: "is",
        discount_type: "is",
        // payment_method_ids: "is",
        max_budget: "=",
        max_redemption: "=",
        max_redemption_per_user: "=",
        amount: "=",
        status: "is",
    }) as any
    const [globalFilter, setGlobalFilter] = useState<any>()
    const [sorting, setSorting] = useState<SortingState>() as any
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 15,
    })

    const sort = useMemo(
        () =>
            sorting?.map(
                (item: {
                    id?: string
                    field?: string
                    sort?: string
                    desc?: string
                }) => ({
                    field: item?.id || item?.field,
                    sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
                })
            ),
        [sorting]
    )
    const discountData = useRef({}) as any
    const axiosPrivate = useAxiosPrivate()

    const { enqueueSnackbar } = useSnackbar()
    const {
        data: discounts,
        isLoading,
        refetch,
    } = useFetchEntitiesPerPage(
        {
            endPoint: `discounts/${discountCode}`,
            page: pagination.pageIndex,
            perPage: pagination.pageSize,
            filterModel: filterModel(columnFilterFns, columnFilters) as any,
            sortModel: sort,
            searchText: globalFilter,
        },
        { enabled: discountDetails }
    ) as any
    const { isLoading: loading, data: banks } = useFetchEntities({
        endPoint: "/payment-methods",
    }) as any
    const { isLoading: loadingProducts, data: products } =
        useFetchEntitiesPerPage({
            endPoint: "products",
            perPage: -1,
        }) as any
    const { isLoading: currenciesLoading, data: currencies } = useFetchEntities(
        {
            endPoint: "/currencies",
        }
    ) as any
    const { mutate, isLoading: updateStatusIsLoading } = useMutation(
        (discount: any) =>
            axiosPrivate.patch(
                `/merchants/${merchantId}/discounts/${discount.id}`,
                {
                    status: discount?.status,
                }
            ),
        {
            onSuccess: () => {
                enqueueSnackbar(`Succesfully changed status.`, {
                    variant: "success",
                    preventDuplicate: true,
                    autoHideDuration: 2000,
                })
                refetch()
            },
            onError: (error: any) =>
                enqueueSnackbar(
                    error.response?.data?.error?.field_error?.[0]
                        ?.description ||
                        error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
        }
    ) as any
    // Event Handlers & Helpers
    const handleSwitch = useCallback(
        (discount: any) => {
            discountData.current = {
                ...discountData.current,
                id: discount?.id,
                status: discount.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
            }
            mutate(discountData.current)
        },
        [mutate]
    )
    const statusButton = useCallback(
        ({
            cell,
        }: {
            cell: { getValue: Getter<string>; row: { original: any } }
        }) => (
            <StatusButton
                loading={updateStatusIsLoading}
                disabled={!updateDiscount}
                status={cell.getValue()}
                onChange={() => handleSwitch(cell?.row?.original)}
            />
        ),
        []
    )
    // const actions = useCallback(
    //     ({ row }: { row: { original: any } }) => (
    //         <Box>
    //             {/* <Tooltip title="Edit discount">
    //                 <IconButton
    //                     sx={{ color: "primary.main" }}
    //                     onClick={() => handleUpdate(row?.original)}
    //                 >
    //                     <Edit />
    //                 </IconButton>
    //             </Tooltip> */}
    //             <Tooltip title="View discount">
    //                 <Link to={`${row.original.id}/view`} state={row.original}>
    //                     <IconButton sx={{ color: "primary.main" }}>
    //                         <Visibility />
    //                     </IconButton>
    //                 </Link>
    //             </Tooltip>
    //             <Tooltip title="Delete discount">
    //                 <IconButton color="error">
    //                     <Delete />
    //                 </IconButton>
    //             </Tooltip>
    //         </Box>
    //     ),
    //     []
    // )
    const method = useCallback(
        ({ row }: { row: { original: { payment_method_ids: string[] } } }) => (
            <PaymentMethods
                rowData={row?.original?.payment_method_ids}
                bankData={banks?.data}
            />
        ),
        [banks?.data]
    )
    const durationAndDate = useCallback(
        ({
            row,
        }: {
            row: {
                original: {
                    duration: string
                    number_of_cycles: string
                    effective_from: string
                    effective_to: string
                }
            }
        }) => (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "bold",
                    my: 0,
                }}
            >
                <Typography sx={{ color: "secondary.main" }}>
                    {capitalizeFirstLetter(row?.original?.duration)}
                </Typography>
                {row?.original?.duration === "REPEATING" && (
                    <Typography
                        sx={{
                            color: "primary.main",
                            fontWeight: "bold",
                        }}
                    >
                        ({row?.original?.number_of_cycles})
                    </Typography>
                )}
                <CustomTimeLine
                    color="primary.main"
                    from={row?.original?.effective_from}
                    to={row?.original?.effective_to}
                />
            </Box>
        ),
        []
    )
    const planAndProduct = useCallback(
        ({ row }: { row: { original: { price_ids: string[] } } }) => {
            const productData = products?.data?.filter((item: any) =>
                item?.prices?.some((price: any) =>
                    row?.original?.price_ids?.includes(price?.id)
                )
            )
            return (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "flex-start",
                    }}
                >
                    {productData?.map((product: any) => (
                        <Box
                            key={product?.id}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                bgcolor: "#fff",
                                borderRadius: 6,
                                py: 1,
                                px: 2,
                                mb: 0.5,
                                mr: 0.5,
                                gap: 0.5,
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{ fontWeight: "bold" }}
                            >
                                {product?.name}
                            </Typography>
                            <FiberManualRecord
                                sx={{
                                    color: "primary.main",
                                    fontSize: 10,
                                }}
                            />
                            <Typography
                                variant="body1"
                                sx={{ alignSelf: "center" }}
                            >
                                {product?.prices[0]?.name}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            )
        },
        [products?.data]
    )
    const currency = useCallback(
        ({ row }: { row: { original: { currency_id: string } } }) =>
            currencies?.data
                ?.filter((item: any) => item?.id === row?.original?.currency_id)
                ?.map((el: any) => el?.code),
        [currencies?.data]
    )
    const dateFilter = useCallback(
        (dateProps: any) => <CustomDateFilter {...dateProps} />,
        []
    )

    // DATA STRUCTURE
    const columns = useMemo<MRT_ColumnDef<object>[]>(
        () => [
            {
                size: 300,
                accessorKey: "created_at",
                header: "Registered Time",
                filterVariant: "date" as any,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
                Cell: ({ cell }: { cell: { getValue: Getter<string> } }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: dateFilter as any,
            },
            {
                size: 240,
                accessorKey: "price_ids",
                header: "Product & Plan",
                enableSorting: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
                Cell: planAndProduct,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            {
                size: 200,
                accessorKey: "payment_method_ids",
                header: "Payment Method",
                enableSorting: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
                Cell: method as any,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            {
                size: 340,
                accessorKey: "duration",
                header: "Duration & Date",
                filterVariant: "select" as any,
                filterSelectOptions: ["ONCE", "REPEATING", "FOREVER"],
                Cell: durationAndDate as any,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            {
                size: 200,
                accessorKey: "max_budget",
                header: "Max Budget",
                filterVariant: "number" as any,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            {
                size: 245,
                accessorKey: "max_redemption",
                header: "Max No. of Customers",
                filterVariant: "number" as any,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            {
                size: 270,
                accessorKey: "max_redemption_per_user",
                header: "Max Redumption per User",
                filterVariant: "number" as any,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            {
                size: 210,
                accessorKey: "discount_type",
                header: "Discount Type",
                filterVariant: "select" as any,
                filterSelectOptions: ["PERCENT", "FLAT"],
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            {
                size: 180,
                accessorKey: "amount",
                header: "Amount",
                filterVariant: "number" as any,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            {
                size: 180,
                accessorKey: "currency_id",
                header: "Currency",
                enableSorting: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
                Cell: currency as any,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            {
                size: 240,
                accessorKey: "status",
                header: "Status",
                filterVariant: "select" as any,
                filterSelectOptions: ["ACTIVE", "INACTIVE"],
                Cell: statusButton,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            // {
            //     size: 200,
            //     accessorKey: "actions",
            //     header: "Actions",
            //     enableSorting: false,
            //     enableColumnFilter: false,
            //     enableGlobalFilter: false,
            //     Cell: actions,
            // },
        ],
        [
            // actions,
            currency,
            dateFilter,
            durationAndDate,
            method,
            planAndProduct,
            statusButton,
        ]
    )
    return (
        <Box sx={{ height: "100%" }}>
            {/* {update && (
                <UpdateDiscount
                    handleUpdateModalClose={handleUpdateModalClose}
                    discountData={discountData.current}
                    update={update}
                    refetch={refetch}
                />
            )} */}
            <Box sx={{ flex: 1, height: "100%" }}>
                <MaterialReactTable
                    data={discounts ? discounts.data : []}
                    columns={columns}
                    initialState={{
                        columnPinning: {
                            left: ["mrt-row-select"],
                            right: ["actions"],
                        },
                    }}
                    enableColumnResizing
                    enableColumnFilterModes
                    enableStickyHeader
                    enableColumnOrdering
                    enableRowSelection
                    enablePinning
                    manualFiltering
                    manualPagination
                    manualSorting
                    filterFns={{
                        after: (row: any, filterValue) =>
                            row.customField === filterValue,
                    }}
                    muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                        helperText: `Filter Mode: ${
                            columnFilterFns[column?.id]
                        }`,
                    })}
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setColumnFilterFns}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <MRT_ToggleFiltersButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <MRT_FullScreenToggleButton table={table} />
                        </>
                    )}
                    muiTableContainerProps={{
                        sx: { maxHeight: `calc(100vh - 225px)` },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            "& .Mui-TableHeadCell-Content": {
                                justifyContent: "space-between",
                            },
                        },
                    }}
                    muiTableBodyCellProps={({ table, column }) => {
                        return {
                            sx: {
                                "&.MuiTableCell-root": {
                                    boxShadow:
                                        table.getState().columnPinning
                                            ?.right?.[0] === column?.id
                                            ? "-7px 0px 10px -1.7px lightgray"
                                            : table
                                                  .getState()
                                                  .columnPinning?.left?.some(
                                                      (el) => el === column.id
                                                  )
                                            ? "7px 0px 10px -1.7px lightgray"
                                            : "none",
                                },
                            },
                        }
                    }}
                    rowCount={discounts?.meta_data?.total ?? 0}
                    state={{
                        columnFilters: columnFilters || [],
                        columnFilterFns,
                        globalFilter: globalFilter || "",
                        isLoading,
                        pagination,
                        sorting: sorting || [],
                        showSkeletons:
                            isLoading ||
                            loading ||
                            loadingProducts ||
                            currenciesLoading,
                    }}
                />
            </Box>
        </Box>
    )
}

export default DiscountsByCode
