import { Box, Chip, Divider, Grid, TextField, Typography } from "@mui/material"
import { useLocation } from "react-router-dom"
import { FiberManualRecord } from "@mui/icons-material"
import { CapitalizeWordAndAddLy } from "../../utils/capitalizeWordAndAddLy"
import { DateTime } from "luxon"

import convertToHours from "../../utils/convertToHours"

function ProductDetails() {
    const location = useLocation()

    // ACCESS THE STATE PROP OF THE LINK OBJECT
    const product = location.state

    return (
        <Grid
            container
            spacing={6}
            justifyContent="space-around"
            sx={{
                flex: 2,
                pl: { xs: 0 },
                pt: "1.5em",
            }}
        >
            <Grid
                item
                xs={12}
                lg={5}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.5em",
                }}
            >
                <Typography variant="body1" sx={{ fontWeight: "500" }}>
                    Product Info
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: ".5em",
                    }}
                >
                    <TextField
                        value={product.name}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        sx={{
                            bgcolor: "#f7f7f8",
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: ".5em",
                    }}
                >
                    <TextField
                        value={product.description}
                        multiline
                        InputProps={{
                            readOnly: true,
                        }}
                        sx={{
                            bgcolor: "#f7f7f8",
                        }}
                        rows={3}
                        fullWidth
                    />
                </Box>
            </Grid>

            <Grid
                item
                xs={12}
                lg={6}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.5em",
                }}
            >
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Plans
                </Typography>
                <Box
                    sx={{
                        maxHeight: { sm: `calc(100vh - 200px)`, xs: `100%` },
                        overflow: "auto",
                        width: "100%",
                        pr: 1,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                        }}
                    >
                        {product.prices.map((price: any) => (
                            <Box
                                key={price.id}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    border: "1px solid",
                                    borderColor: "primary.main",
                                    borderRadius: 2,
                                    gap: "1em",
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{ fontWeight: "bold", px: 2, mt: 2 }}
                                >
                                    {price.name}
                                </Typography>
                                <Divider sx={{ mx: 2 }} />
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 2,
                                        px: 2,
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        {CapitalizeWordAndAddLy(
                                            price.frequency
                                        )}
                                    </Typography>
                                    <FiberManualRecord
                                        sx={{
                                            color: "secondary.main",
                                            fontSize: 10,
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "0.2em",
                                            alignItems: "baseline",
                                        }}
                                    >
                                        <Typography variant="body1">
                                            {price.amount}
                                        </Typography>
                                        <Typography sx={{ fontSize: 12 }}>
                                            {price.currency.code}
                                        </Typography>
                                    </Box>
                                    <Chip
                                        variant="filled"
                                        label={
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    gap: 1,
                                                    p: 0.5,
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: "secondary.main",
                                                    }}
                                                >
                                                    Recurring
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        color: "primary.main",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    ({price.recurring_cycle})
                                                </Typography>
                                            </Box>
                                        }
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "0.5em",
                                            px: 2,
                                            pb: 2,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                gap: "0.2em",
                                            }}
                                        >
                                            <Typography
                                                sx={{ fontWeight: "bold" }}
                                            >
                                                Grace Period
                                            </Typography>
                                            <Typography>
                                                {price?.grace_period
                                                    ? `(${convertToHours(
                                                          price?.grace_period
                                                      )} hr)`
                                                    : "(0 hr)"}
                                            </Typography>
                                        </Box>
                                        <FiberManualRecord
                                            sx={{
                                                color: "secondary.main",
                                                fontSize: 10,
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                display: "flex",
                                                gap: "0.2em",
                                            }}
                                        >
                                            <Typography
                                                sx={{ fontWeight: "bold" }}
                                            >
                                                Expire Date
                                            </Typography>
                                            <Typography>
                                                (
                                                {DateTime.fromISO(
                                                    price.expiration
                                                ).toLocaleString(
                                                    DateTime.DATETIME_MED_WITH_SECONDS
                                                )}
                                                )
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default ProductDetails
